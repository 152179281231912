import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';
import LinkMui from 'components/common/Link';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import api from 'constants/api';

import SelectMenu from 'components/common/SelectMenu';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from 'components/common/Typography';
import Tooltip from 'components/common/Tooltip';
import colors from 'constants/colors';
import { LinkIcon } from 'components/icons/mui_icons';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

function Component({ companyId }: { companyId: string | undefined }) {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const { data: SectorData } = useQuery({
    queryKey: ['companies-sectors'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const { data } = useQuery({
    enabled: !!companyId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['companies-company-collections'],
    retry: false,
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/admin/company/collections?company=${companyId}`,
        Object.assign(auth.requestConfig, { params: {} })
      );
      return res.data;
    },
  });
  const [selectedCollection, setSelectedCollection] = React.useState({});

  useEffect(() => {
    if (data) {
      setSelectedCollection(data[0]);
    }
  }, [data]);

  const filterCollectionByName = (name: string) => {
    return data.filter((result: any) => result.name === name);
  };

  const getUniqueTargets = (targets: any) => {
    let unigue_targets = new Set();

    targets.forEach((t: any) => {
      if (t.framework === null && t.job !== null) {
        //means that it comes from public screening
        unigue_targets.add(`PS_${t.source_channel}`.toUpperCase());
      } else {
        unigue_targets.add(t.source_channel.toUpperCase());
      }
    });
    return Array.from(unigue_targets).join(', ');
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/company/${params.row.company_slug}`}
            target="_blank"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            <Tooltip title={params.row.company_slug}>
              <div>{params.row.name}</div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: 'company_slug',
      headerName: 'Data Services',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/admin/company/${params.row.company_id}`}
            target="_blank"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            <Tooltip title={params.row.name}>
              <LinkIcon />
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      minWidth: 100,
      flex: 2,
    },
    {
      field: 'website',
      headerName: 'Website',
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <LinkMui
            href={`https://${params.row.website}`}
            target="_blank"
            rel="noopener"
            underline="none"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            {params.row.website}
          </LinkMui>
        );
      },
    },
    {
      field: 'Isin',
      headerName: 'isin',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'reporting_company',
      headerName: 'Reporting Company',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'sector',
      headerName: 'Sector',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'last_screened',
      headerName: 'Last screened',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'latest_cdp_year',
      headerName: 'CDP - Latest year ',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'company_targets',
      headerName: 'Targets',
      minWidth: 180,
      flex: 1,
    },
  ];

  const rows = (selectedCollection as any)?.companies?.map(
    (company: any, index: any) => {
      return {
        id: index,
        company_id: company.id,
        company_slug: company.slug,
        name: company.name,
        country: company.country,
        website: company.website,
        isin: company.isin,
        reporting_company: company.reporting_company,
        sector: SectorData ? SectorData[company.sector] : null,
        last_screened: company.last_screened,
        latest_cdp_year: company.cdp_summary
          ? company.cdp_summary[0].year
          : null,
        company_targets: getUniqueTargets(company.company_targets),
      };
    }
  );

  return data && data.length > 0 ? (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      sx={{
        width: { xs: 'calc(100% - 65px)', lg: '100%' },
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontSize: '24px', color: colors.neutral400 }}
      >
        Company Collections
      </Typography>
      <Box width={'20%'}>
        <Typography variant="h6">Please Choose a Collection</Typography>
        <SelectMenu
          items={data.map((item: any) => item.name)}
          handleChange={(e: any) => {
            setSelectedCollection(filterCollectionByName(e.target.value)[0]);
          }}
          value={(selectedCollection as any)?.name}
        />
      </Box>
      <Box sx={{ height: 640, width: '100%' }}>
        <Typography variant="h6">Companies in collection</Typography>
        {rows ? (
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        ) : null}
      </Box>
    </Stack>
  ) : (
    <div>this company has no collections</div>
  );
}

export default observer(Component);
